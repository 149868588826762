import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: "43px"
	},
	datetime: {
		width: "100%",
	},
}));

