import {getApprovedCards as getApprovedCardsAPI} from "../../api/api";
import {TYPE_FETCH_APPROVED_CARDS, TYPE_FETCH_APPROVED_CARDS_FAILURE, TYPE_FETCH_APPROVED_CARDS_SUCCESS} from "./types";

export function fetchApprovedCards(apiType) {
	return function(dispatch) {
		dispatch(fetchApprovedCardsAction());

		return getApprovedCardsAPI(apiType).then((response) => {
			dispatch(fetchApprovedCardsSuccessAction(response));
		}).catch((error) => {
			dispatch(fetchApprovedCardsFailureAction(error));
		});
	};
}

export function fetchApprovedCardsAction() {
	return {
		type: TYPE_FETCH_APPROVED_CARDS,
		isLoading: true,
	};
}

export function fetchApprovedCardsSuccessAction(response) {
	return {
		type: TYPE_FETCH_APPROVED_CARDS_SUCCESS,
		isLoading: false,
		response: response,
	};
}

export function fetchApprovedCardsFailureAction(error) {
	return {
		type: TYPE_FETCH_APPROVED_CARDS_FAILURE,
		isLoading: false,
		error,
	};
}
