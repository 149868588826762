import * as React from "react";
import {useStyles} from "./cardDetailsSelectCargo.styles";
import classnames from "classnames";
import {getFormattedDateTime, getDayDifference, getHumanDays} from "../../helpers/datetime";
import {ICard} from "../../interfaces/getApprovedCardsResponse";
import {ICardDetails} from "../../interfaces/cardDetails";
import {isOutOfTheTimeRange} from "../../helpers/cards";
import {CardSubType} from "../card/extensions/cardSubType/cardSubType.component";
import {CardErrors} from "../card/extensions/cardErrors/cardErrors.component";

export function CardDetailsSelectCargo(props) {
	const {card, cardDetails, color}: {card: ICard, cardDetails: ICardDetails, color: string} = props;
	const classes = useStyles({});
	let cardColor = color || "default";
	const tooLateToPick = isOutOfTheTimeRange(card);
	const daysIndicatorClasses = tooLateToPick ? classes.daysIndicatorNegative : classes.daysIndicatorPositive;
	const diffDays = getDayDifference(new Date(), new Date(card.plannedTransportTime));

	return (
		<>
			<div className={classnames(classes.table)}>
				<div className={classnames(classes.row, classes.cardHeader, classes[cardColor])}>{card.cardNumber}</div>
				<div className={classes.row}>
					<div className={classes.col1}>Przekazujacy</div>
					<div className={classes.col2}>{card.senderName}</div>
				</div>
				<div className={classes.row}>
					<div className={classes.col1}>Przyjmujący</div>
					<div className={classes.col2}>{card.receiverName}</div>
				</div>
				{card.plannedTransportTime && <div className={classes.row}>
					<div className={classes.col1}>Planowany czas transportu</div>
					<div className={classes.col2} title={card.plannedTransportTime}>{getFormattedDateTime(card.plannedTransportTime)} (<span className={daysIndicatorClasses}>{getHumanDays(diffDays)}</span>)</div>
				</div>}
				<div className={classes.row}>
					<div className={classes.col1}>Nr rejestracyjny pojazdu</div>
					<div className={classes.col2}>{card.vehicleRegNumber}</div>
				</div>
				<div className={classes.row}>
					<div className={classes.col1}>Kod odpadu</div>
					<div className={classes.col2}>{card.wasteCode} - {card.wasteCodeDescription}</div>
				</div>
				<CardSubType card={card} />
				{cardDetails && <div className={classes.row}>
					<div className={classes.col1}>Waga</div>
					<div className={classes.col2}>{cardDetails.wasteMass} Mg</div>
				</div>}
				<CardErrors card={card} />
			</div>
		</>
	);
}

