import {connect} from "react-redux";
import {ActiveCargosComponent} from "./activeCargos.component";
import {fetchConfirmationGeneratedCards, printConfirmation} from "../../../../store/actions";
import {clearRefreshCurrentPageAction} from "../../../../../../store/actions";
import {getConfirmationGeneratedCards} from "../../../../store/selectors/app.selectors";
import {getApiType, getRefreshCurrentPageStatus} from "../../../../../../store/selectors/app.selectors";

const mapStateToProps = (state) => {
	return {
		confirmationGeneratedCards: getConfirmationGeneratedCards(state),
		refreshCurrentPageStatus: getRefreshCurrentPageStatus(state),
		apiType: getApiType(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		fetchConfirmationGeneratedCards: (apiType) => {dispatch(fetchConfirmationGeneratedCards(apiType));},
		printConfirmation: (apiType, card) => {dispatch(printConfirmation(apiType, card));},
		clearRefreshCurrentPage: () => {dispatch(clearRefreshCurrentPageAction());},
	};
};

export const ActiveCargos = connect(
	mapStateToProps,
	mapDispatchToProps
)(ActiveCargosComponent);
