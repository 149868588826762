import {Locations} from "../../constants/locations";

export function getGenerateConfirmationCardsSuccessReducer(state, action) {
	return {
		...state,
		isLoading: action.isLoading,
		isDisconnected: false,
		error: null,
		locationToChangeTo: Locations.ACTIVE_CARGOS,
		exchangedData: {},
	};
}
