import * as React from "react";
import {Dialog} from "../../../../../components/dialog";

export function EndTransportDialog(props) {
	const {open = false, onCancel, onConfirm, onClose} = props;

	return (
		<Dialog
			open={open}
			onConfirm={onConfirm}
			onCancel={onCancel}
			onClose={onClose}
			title={"Koniec transportu"}
			description={"Czy jesteś pewien że chcesz zakończyć transport?"}
			confirmButtonLabel={"Tak"}
			cancelButtonLabel={"Nie"}
		/>
	);
}

