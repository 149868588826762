import * as React from "react";
import {useStyles} from "./card.styles";
import classnames from "classnames";
import {getFormattedDateTime} from "../../helpers/datetime";
import {ICard} from "../../interfaces/getApprovedCardsResponse";
import {CardSubType} from "./extensions/cardSubType/cardSubType.component";

export function Card(props) {
	const {card, color}: {card: ICard, color: string} = props;
	const classes = useStyles({});
	let cardColor = color || "default";

	return (
		<>
			<div className={classnames(classes.table)}>
				<div className={classnames(classes.row, classes.cardHeader, classes[cardColor])}>{card.cardNumber}</div>
				{card.revisionNumber > 0 && <div className={classes.row}>
					<div className={classes.col1}>Korekta</div>
					<div className={classes.col2}>nr {card.revisionNumber}</div>
				</div>}
				<div className={classes.row}>
					<div className={classes.col1}>Przekazujacy</div>
					<div className={classes.col2}>{card.senderName}</div>
				</div>
				<div className={classes.row}>
					<div className={classes.col1}>Przyjmujący</div>
					<div className={classes.col2}>{card.receiverName}</div>
				</div>
				{card.realTransportTime && <div className={classes.row}>
					<div className={classes.col1}>Rzeczywisty czas transportu</div>
					<div className={classes.col2} title={card.realTransportTime}>{getFormattedDateTime(card.realTransportTime)}</div>
				</div>}
				<div className={classes.row}>
					<div className={classes.col1}>Nr rejestracyjny pojazdu</div>
					<div className={classes.col2}>{card.vehicleRegNumber}</div>
				</div>
				<div className={classes.row}>
					<div className={classes.col1}>Kod odpadu</div>
					<div className={classes.col2}>{card.wasteCode} - {card.wasteCodeDescription}</div>
				</div>
				<CardSubType card={card} />
			</div>
		</>
	);
}

