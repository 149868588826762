import axios from "axios";
import {CardTypes} from "../../../constants/cardTypes";
import Cookies from "js-cookie";
import {getServerUrl} from "../../../helpers/server";

export function generateConfirmation(apiType, data) {
	return axios({
		method: "put",
		url: `${getServerUrl()}/${apiType}/carrier/generateconfirmation`,
		data: data,
		headers: {
			"Content-Type": "application/json",
			"Authorization": "Bearer " + Cookies.get("mbdo_at"),
		},
	});
}

export function printConfirmation(apiType, cardId) {
	return axios({
		method: "get",
		url: `${getServerUrl()}/${apiType}/carrier/card/${cardId}/printconfirmation`,
		responseType: "blob",
		headers: {
			"Content-Type": "application/pdf",
			"Authorization": "Bearer " + Cookies.get("mbdo_at"),
		},
	});
}

export function getCards(apiType, cardType) {
	return axios({
		method: "get",
		url: `${getServerUrl()}/${apiType}/carrier/card?status=${cardType}`,
		headers: {
			"Content-Type": "application/json",
			"Authorization": "Bearer " + Cookies.get("mbdo_at"),
		},
	});
}

export function getApprovedCards(apiType) {
	return getCards(apiType, CardTypes.APPROVED);
}

export function getConfirmationGeneratedCards(apiType) {
	return getCards(apiType, CardTypes.CONFIRMATION_GENERATED);
}

export function getRejectedCards(apiType) {
	return getCards(apiType, CardTypes.REJECTED);
}

export function getReceiveConfirmationCards(apiType) {
	return getCards(apiType, CardTypes.RECEIVE_CONFIRMATION);
}

export function finishTransport(apiType, cardId) {
	return axios({
		method: "put",
		url: `${getServerUrl()}/${apiType}/carrier/transportconfirmation`,
		data: {
			cardId,
		},
		headers: {
			"Content-Type": "application/json",
			"Authorization": "Bearer " + Cookies.get("mbdo_at"),
		},
	});
}

export function finishTransportKPOK(apiType, cardId, receiptEndingTime) {
	return axios({
		method: "put",
		url: `${getServerUrl()}/${apiType}/carrier/transportconfirmation`,
		data: {
			cardId,
			receiptEndingTime,
		},
		headers: {
			"Content-Type": "application/json",
			"Authorization": "Bearer " + Cookies.get("mbdo_at"),
		},
	});
}

export function getCardDetails(apiType, cardId) {
	return axios({
		method: "get",
		url: `${getServerUrl()}/${apiType}/carrier/card/${cardId}/statusApproved`,
		headers: {
			"Content-Type": "application/json",
			"Authorization": "Bearer " + Cookies.get("mbdo_at"),
		},
	});
}

export function getRejectedCardDetails(apiType, cardId) {
	return axios({
		method: "get",
		url: `${getServerUrl()}/${apiType}/carrier/card/${cardId}/statusRejected`,
		headers: {
			"Content-Type": "application/json",
			"Authorization": "Bearer " + Cookies.get("mbdo_at"),
		},
	});
}



