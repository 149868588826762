import {toast} from "react-toastify";
import {getPrintConfirmationConfiguration} from "../../helpers/toast";

export function getPrintConfirmationSuccessReducer(state, action) {
	toast.success("Upewnij się przed wyjazdem że potwierdzenie przewozu jest zapisane na telefonie.", getPrintConfirmationConfiguration());

	return {
		...state,
		isLoading: action.isLoading,
		isDisconnected: false,
		error: null,
	};
}
