import * as React from "react";
import {
	TextField,
} from "@material-ui/core";
import {Field} from "react-final-form";
import {ChangeEvent} from "react";

const datePickerAdapter = ({input, meta, serverErrors, onChange, ...rest}) => {
	const componentName = input.name;
	const found = (serverErrors || []).find((error) => {
		return error.name === componentName;
	});

	const hasError = (meta.error && meta.touched) || !!found;
	const errorMessage = (meta.error && meta.touched && meta.error) || (found && found.value);

	return (
		<TextField
			variant="outlined"
			size="small"
			helperText={errorMessage}
			error={hasError}
			id="date"
			label="Birthday"
			type="date"
			InputLabelProps={{
				shrink: true,
			}}
			onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
				const value = event.target.value;
				onChange && onChange(event, value);
				return input.onChange(value);
			}}
			value={input.value}
			name={input.name}
			{...rest}
		/>
	);
};

export function DatePicker(props) {
	return (
		<Field
			component={datePickerAdapter}
			{...props}
		/>
	);
}
