import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	root: {

	},
	datetime: {
		width: "100%",
	},
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	actionButtons: {
		paddingTop: "10px",
	},
	endCargoButton: {
		marginBottom: theme.spacing(2),
	},
	confirmationButton: {
		marginBottom: theme.spacing(2),
	},
	adviceContainer: {
		padding: "10px"
	}
}));

