import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	root: {

	},
	actionButtons: {
		paddingTop: "10px",
	},
	confirmationButton: {
		marginBottom: theme.spacing(2),
	},
}));

