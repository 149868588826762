import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	root: {

	},
	row: {
		display: "flex",
		flexDirection: "row",
		flexGrow: 1
	},
	rowErrors: {
		display: "block",
		padding: "5px",
	},
	col1: {
		flexBasis: "40%",
		textAlign: "left",
		padding: "4px",
		fontWeight: "bold",
	},
	col2: {
		flexBasis: "60%",
		textAlign: "left",
		padding: "4px",
	},

	table: {
		display: "flex",
		flexDirection: "column",
		fontSize: "13px",
	},
	cardHeader: {
		padding: "4px 7px",
		color: "black",
		fontWeight: "bold",
		fontSize: "14px",
		backgroundColor: "rgba(26, 108, 64, 0.4)",
	},
	cardWarningHeader: {
		padding: "4px 7px",
		color: "red",
		fontSize: "14px",
	},
	highlight: {
		backgroundColor: "rgba(255, 242, 0, 0.4)",
	},
	error: {
		backgroundColor: "rgba(227,6,19, 0.4)",
	},
	reciever: {
		backgroundColor: "#88bad1",
		color: "white",
	},
	daysIndicatorPositive: {
		color: "#3bb573",
	},
	daysIndicatorNegative: {
		color: "#e30613",
	}
}));






