import * as React from "react";
import {useStyles} from "./activeCargos.styles";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {ActiveCargos} from "./components/activeCargos/activeCargos.container";
import {CargosToTerminate} from "./components/cargosToTerminate/cargosToTerminate.container";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`wrapped-tabpanel-${index}`}
			aria-labelledby={`wrapped-tab-${index}`}
			{...other}
		>
			{value === index ? children : null}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

export function ActiveCargosComponent(props) {
	const classes = useStyles({});
	const [value, setValue] = React.useState("one");

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<>
			<div className={classes.coverSpace} />
			<AppBar position="fixed" className={classes.appBar}>
				<Tabs
					value={value}
					onChange={handleChange}
					centered
				>
					<Tab
						value="one"
						label="Aktywne"
					/>
					<Tab
						value="two"
						label="Do zakończenia"
					/>
				</Tabs>
			</AppBar>
			<TabPanel value={value} index="one">
				<ActiveCargos />
			</TabPanel>
			<TabPanel value={value} index="two">
				<CargosToTerminate />
			</TabPanel>
		</>
	);
}
