import {getRejectedCards as getRejectedCardsAPI} from "../../api/api";
import {TYPE_FETCH_REJECTED_CARDS, TYPE_FETCH_REJECTED_CARDS_FAILURE, TYPE_FETCH_REJECTED_CARDS_SUCCESS} from "./types";

export function fetchRejectedCards(apiType) {
	return function(dispatch) {
		dispatch(fetchRejectedCardsAction());

		return getRejectedCardsAPI(apiType).then((response) => {
			dispatch(fetchRejectedCardsSuccessAction(response));
		}).catch((error) => {
			dispatch(fetchRejectedCardsFailureAction(error));
		});
	};
}

export function fetchRejectedCardsAction() {
	return {
		type: TYPE_FETCH_REJECTED_CARDS,
		isLoading: true,
	};
}

export function fetchRejectedCardsSuccessAction(response) {
	return {
		type: TYPE_FETCH_REJECTED_CARDS_SUCCESS,
		isLoading: false,
		response: response,
	};
}

export function fetchRejectedCardsFailureAction(error) {
	return {
		type: TYPE_FETCH_REJECTED_CARDS_FAILURE,
		isLoading: false,
		error,
	};
}
