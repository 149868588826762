import {IGetCardsResponse} from "../../interfaces/getApprovedCardsResponse";
import {sortByDateAsc} from "../../helpers/datetime";

export function getFetchRejectedCardsSuccessReducer(state, action) {
	const rejectedCardsResponse: IGetCardsResponse = action.response.data;

	return {
		...state,
		isLoading: action.isLoading,
		isDisconnected: false,
		error: null,
		rejectedCards: sortByDateAsc(rejectedCardsResponse.items, "cardRejectionTime", false),
	};
}
