import * as React from "react";
import {useEffect} from "react";
import {useStyles} from "./rejectedCargos.styles";
import {RejectedCard} from "./rejectedCard.component";
import {Header} from "../../../../components/header/header.component";
import {Container} from "../../../../components/container/container.component";
import {Form} from "react-final-form";
import {FormFieldWrapper} from "../../../../components/formFieldWrapper/formFieldWrapper.component";
import {Select} from "../../../../components/select/select";
import {MenuItem} from "@material-ui/core";
import {IRejectedCargosFormValues} from "../../interfaces/forms/rejectedCargos";
import {
	getCardsByRegistrationNumber, getFilteredCardsByRealTransportTime,
	getInitialRegistrationNumber, getRealTransportTimeFilter, getRegistrationNumberFromCookie,
	getUniqueRegistrationNumbers, isCustomRegistrationNumberEnabled, isRealTransportTimeFilterEnabled
} from "../../../../helpers/cards";
import {Message} from "../../../../components/message";
import Cookies from "js-cookie";
import {DatePicker} from "../../../../components/datePicker/datePicker";
import CloseIcon from "@material-ui/icons/Close";
import {Button} from "../../../../components/button/button";


export function RejectedCargosComponent(props) {
	const {
		fetchRejectedCards, printConfirmation, rejectedCards, apiType,
		getRejectedCardDetails, cardsDetails, isFetchingCardDetails
	} = props;
	const classes = useStyles({});

	useEffect(() => {
		fetchRejectedCards(apiType);
	}, []);

	function handlePrintConfirmation(card) {
		printConfirmation(apiType, card);
	}

	function handleShowDetails(card, expanded) {
		if (expanded) {
			getRejectedCardDetails(apiType, card.kpoId || card.kpokId);
		}
	}

	function getRejectedCardsSection(vehicleRegNumber, realTransportTime) {
		let cards = getCardsByRegistrationNumber(rejectedCards, vehicleRegNumber);

		if (realTransportTime) {
			cards = getFilteredCardsByRealTransportTime(cards, realTransportTime, "cardRejectionTime");
		}

		return (
			<>
				<Header>
					Odrzucone transporty {cards && cards.length ? `(${cards.length})` : ""}
				</Header>
				<div>
					{getRejectedCardsTable(cards)}
				</div>
			</>
		);
	}

	function getRejectedCardsTable(cards) {
		if (!cards) {
			return (<Message>Ładowanie wyników...</Message>);
		} else if (!cards.length) {
			return (<Message>Brak wyników</Message>);
		} else {
			return cards.map((card, index) => {
				const cardDetails = cardsDetails[card.kpoId || card.kpokId] || null;
				const isFetchingCard = isFetchingCardDetails[card.kpoId || card.kpokId] || false;

				return (
					<RejectedCard
						key={card.kpoId || card.kpokId}
						card={card}
						cardDetails={cardDetails}
						isFetchingCardDetails={isFetchingCard}
						onPrintConfirmationClick={handlePrintConfirmation}
						onShowDetailsClick={handleShowDetails}
					/>
				);
			});
		}
	}

	const onSubmit = async (values: IRejectedCargosFormValues) => {

	};

	const handleVehicleNumberChange = (value) => {
		if (!isCustomRegistrationNumberEnabled()) {
			Cookies.set("mbdo_vehicleRegNumber", value);
		}
	};

	const handleRealTransportTimeChange = (e, value) => {
		if (isRealTransportTimeFilterEnabled()) {
			Cookies.set("mbdo_realTransportTimeFilter", value);
		}
	};

	const allCards = rejectedCards || [];
	const uniqueRegistrationNumbers = getUniqueRegistrationNumbers(allCards);
	const initialRegistrationNumber = getInitialRegistrationNumber(
		getRegistrationNumberFromCookie(),
		uniqueRegistrationNumbers,
	);
	const initialRealTransportTimeFilter = isRealTransportTimeFilterEnabled() ? getRealTransportTimeFilter() : undefined;

	const initialValues: IRejectedCargosFormValues = {
		vehicleRegNumber: initialRegistrationNumber,
		realTransportTime: initialRealTransportTimeFilter,
	};

	return (
		<>
			<Container>
				<Form
					onSubmit={onSubmit}
					initialValues={initialValues}
					render={({ handleSubmit, form, submitting, pristine, values , }) => {
						return (
							<>
								<form onSubmit={handleSubmit}>
									{allCards.length > 0 && <FormFieldWrapper>
										<Select
											required={true}
											name="vehicleRegNumber"
											label="Filtruj wg. numeru rejestracyjnego"
											onChange={handleVehicleNumberChange}
										>
											{uniqueRegistrationNumbers.map((regNumber) => {
												return (<MenuItem key={regNumber} value={regNumber}>{regNumber}</MenuItem>);
											})}
										</Select>
									</FormFieldWrapper>}
									{allCards.length > 0 && isRealTransportTimeFilterEnabled() && <FormFieldWrapper>
										<DatePicker
											name="realTransportTime"
											label="Filtruj wg. daty odrzucenia karty"
											className={classes.datetime}
											onChange={handleRealTransportTimeChange}
										/>
										<Button startIcon={<CloseIcon />} onClick={() => {
											form.change("realTransportTime", '');
											handleRealTransportTimeChange(null, '');
										}} />
									</FormFieldWrapper>}

									{getRejectedCardsSection(values.vehicleRegNumber, values.realTransportTime)}
								</form>
							</>
						);
					}}
				/>
			</Container>
		</>
	);
}
