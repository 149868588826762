import * as React from "react";
import {useStyles} from "../../card.styles";
import {ICard} from "../../../../interfaces/getApprovedCardsResponse";
import {hasMissingInfoAboutCommune, isOutOfTheTimeRange} from "../../../../helpers/cards";
import {Advice} from "../../../advice";
import classnames from "classnames";
import {ICardDetails} from "../../../../interfaces/cardDetails";

export function CardErrors(props) {
	const {card, cardDetails}: {card: ICard, cardDetails: ICardDetails} = props;
	const classes = useStyles({});
	const tooLateToPick = isOutOfTheTimeRange(card);
	const missingInfoAboutCommune = hasMissingInfoAboutCommune(cardDetails);
	const errors = [];

	if (tooLateToPick) {
		errors.push(<Advice key={errors.length} type="error">Przewozy można podejmować tylko w odstępie +/- 1 dnia od planowanego czasu transportu.</Advice>);
	}

	if (missingInfoAboutCommune) {
		errors.push(<Advice key={errors.length} type="error">Karta nie zawiera informacji o nazwie gminy lub jej obszarze. Prosimy o kontakt z osobą wystawiającą kartę o uzupełnienie brakujących informacji.</Advice>);
	}

	if (errors.length) {
		return (
			<div className={classnames(classes.row, classes.rowErrors)}>
				{errors}
			</div>
		);
	} else {
		return null;
	}
}

