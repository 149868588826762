import * as React from "react";
import {
	TextField,
} from "@material-ui/core";
import {Field} from "react-final-form";

const timePickerAdapter = ({input, meta, serverErrors, ...rest}) => {
	const componentName = input.name;
	const found = (serverErrors || []).find((error) => {
		return error.name === componentName;
	});

	const hasError = (meta.error && meta.touched) || !!found;
	const errorMessage = (meta.error && meta.touched && meta.error) || (found && found.value);

	return (
		<TextField
			variant="outlined"
			size="small"
			helperText={errorMessage}
			error={hasError}
			type="time"
			value={input.value}
			InputLabelProps={{
				shrink: true,
			}}
			inputProps={{
				step: 60, // 5 min
			}}
			{...input}
			{...rest}
		/>
	);
};

export function TimePicker(props) {
	return (
		<Field
			component={timePickerAdapter}
			{...props}
		></Field>
	);
}
