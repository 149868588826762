export function getApprovedCards(state) {
	return state.app.approvedCards;
}

export function getConfirmationGeneratedCards(state) {
	return state.app.confirmationGeneratedCards;
}

export function getRejectedCards(state) {
	return state.app.rejectedCards;
}

export function getCardsDetails(state) {
	return state.app.cardsDetails;
}

export function getReceiveConfirmationCards(state) {
	return state.app.receiveConfirmationCards;
}

export function isFetchingCardDetails(state) {
	return state.app.isFetchingCardDetails;
}

export function getSelectedCardDetails(state) {
	return state.app.selectedCardDetails;
}

export function getSelectedCardOnSelectCargoScreen(state) {
	return state.app.selectedCardOnSelectCargoScreen;
}
