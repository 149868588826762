import {getErrorHandling} from "../../../../helpers/errors";

export function getRejectedCardDetailsReducer(state, action) {
	return {
		...state,
		error: null,
		isFetchingCardDetails: {
			...state.isFetchingCardDetails,
			[action.cardId]: true
		},
	};
}

export function getRejectedCardDetailsSuccessReducer(state, action) {
	const cardDetails = action.response.data;

	return {
		...state,
		isFetchingCardDetails: {
			...state.isFetchingCardDetails,
			[action.cardId]: false
		},
		isDisconnected: false,
		selectedCardDetails: action.response.data,
		error: null,
		cardsDetails: {
			...state.cardsDetails,
			[cardDetails.kpoId || cardDetails.kpokId]: cardDetails,
		}
	};
}

export function getRejectedCardDetailsFailureReducer(state, action) {
	return {
		...state,
		isFetchingCardDetails: {
			...state.isFetchingCardDetails,
			[action.cardId]: false,
		},
		...getErrorHandling(action, state),
	};
}
