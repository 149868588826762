import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './css/index.css';
import {App} from './bootstrap/carrier/containers/app';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from "./bootstrap/carrier/store/store";
import "react-toastify/dist/ReactToastify.css";

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


