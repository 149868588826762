import * as React from "react";
import {Button} from "../button/button";
import {useStyles} from "./cardActive.styles";
import {globalStyles} from "../../css/global.styles";
import classnames from "classnames";
import {Card} from "../card/card.component";
import {CardWrapper} from "../cardWrapper/cardWrapper.component";

export function CardActive(props) {
	const {card} = props;
	const classes = useStyles({});
	const globalClasses = globalStyles({});

	function handlePrintConfirmation() {
		const {onPrintConfirmationClick, card} = props;

		onPrintConfirmationClick(card);
	}

	return (
		<CardWrapper>
			<Card card={card} color="default"/>

			<div className={classnames(classes.actionButtons , globalClasses.centerButtons)}>
				<Button onClick={handlePrintConfirmation} className={classes.confirmationButton} variant="contained" color="primary">Pobierz potwierdzenie</Button>
			</div>
		</CardWrapper>
	);
}
