export const TYPE_FETCH_APPROVED_CARDS = "TYPE_FETCH_APPROVED_CARDS";
export const TYPE_FETCH_APPROVED_CARDS_SUCCESS = "TYPE_FETCH_APPROVED_CARDS_SUCCESS";
export const TYPE_FETCH_APPROVED_CARDS_FAILURE = "TYPE_FETCH_APPROVED_CARDS_FAILURE";
export const TYPE_FETCH_CONFIRMATION_GENERATED_CARDS = "TYPE_FETCH_CONFIRMATION_GENERATED_CARDS";
export const TYPE_FETCH_CONFIRMATION_GENERATED_CARDS_SUCCESS = "TYPE_FETCH_CONFIRMATION_GENERATED_CARDS_SUCCESS";
export const TYPE_FETCH_CONFIRMATION_GENERATED_CARDS_FAILURE = "TYPE_FETCH_CONFIRMATION_GENERATED_CARDS_FAILURE";
export const TYPE_FETCH_REJECTED_CARDS = "TYPE_FETCH_REJECTED_CARDS";
export const TYPE_FETCH_REJECTED_CARDS_SUCCESS = "TYPE_FETCH_REJECTED_CARDS_SUCCESS";
export const TYPE_FETCH_REJECTED_CARDS_FAILURE = "TYPE_FETCH_REJECTED_CARDS_FAILURE";
export const TYPE_PRINT_CONFIRMATION = "TYPE_PRINT_CONFIRMATION";
export const TYPE_PRINT_CONFIRMATION_SUCCESS = "TYPE_PRINT_CONFIRMATION_SUCCESS";
export const TYPE_PRINT_CONFIRMATION_FAILURE = "TYPE_PRINT_CONFIRMATION_FAILURE";
export const TYPE_FINISH_TRANSPORT = "TYPE_FINISH_TRANSPORT";
export const TYPE_FINISH_TRANSPORT_SUCCESS = "TYPE_FINISH_TRANSPORT_SUCCESS";
export const TYPE_FINISH_TRANSPORT_FAILURE = "TYPE_FINISH_TRANSPORT_FAILURE";
export const TYPE_FINISH_TRANSPORT_KPOK = "TYPE_FINISH_TRANSPORT_KPOK";
export const TYPE_FINISH_TRANSPORT_KPOK_SUCCESS = "TYPE_FINISH_TRANSPORT_KPOK_SUCCESS";
export const TYPE_FINISH_TRANSPORT_KPOK_FAILURE = "TYPE_FINISH_TRANSPORT_KPOK_FAILURE";
export const TYPE_FETCH_RECEIVE_CONFIRMATION_CARDS = "TYPE_FETCH_RECEIVE_CONFIRMATION_CARDS";
export const TYPE_FETCH_RECEIVE_CONFIRMATION_CARDS_SUCCESS = "TYPE_FETCH_RECEIVE_CONFIRMATION_CARDS_SUCCESS";
export const TYPE_FETCH_RECEIVE_CONFIRMATION_CARDS_FAILURE = "TYPE_FETCH_RECEIVE_CONFIRMATION_CARDS_FAILURE";
export const TYPE_GENERATE_CONFIRMATION_CARDS = "TYPE_GENERATE_CONFIRMATION_CARDS";
export const TYPE_GENERATE_CONFIRMATION_CARDS_SUCCESS = "TYPE_GENERATE_CONFIRMATION_CARDS_SUCCESS";
export const TYPE_GENERATE_CONFIRMATION_CARDS_FAILURE = "TYPE_GENERATE_CONFIRMATION_CARDS_FAILURE";
export const TYPE_GET_CARD_DETAILS = "TYPE_GET_CARD_DETAILS";
export const TYPE_GET_CARD_DETAILS_SUCCESS = "TYPE_GET_CARD_DETAILS_SUCCESS";
export const TYPE_GET_CARD_DETAILS_FAILURE = "TYPE_GET_CARD_DETAILS_FAILURE";
export const TYPE_GET_REJECTED_CARD_DETAILS = "TYPE_GET_REJECTED_CARD_DETAILS";
export const TYPE_GET_REJECTED_CARD_DETAILS_SUCCESS = "TYPE_GET_REJECTED_CARD_DETAILS_SUCCESS";
export const TYPE_GET_REJECTED_CARD_DETAILS_FAILURE = "TYPE_GET_REJECTED_CARD_DETAILS_FAILURE";
export const TYPE_SELECT_CARD_ON_SELECT_CARGO_SCREEN = "TYPE_SELECT_CARD_ON_SELECT_CARGO_SCREEN";
