import {connect} from "react-redux";
import {TopMenuComponent} from "./topMenu.component";
import {logoutAction, changeApiTypeAction} from "../../../../store/actions";
import {
	isLoggedIn,
	isExpired,
	hasBdoCredentials,
	getApiType, getAccountData
} from "../../../../store/selectors/app.selectors";

const mapStateToProps = (state) => {
	return {
		isLoggedIn: isLoggedIn(state),
		accountData: getAccountData(state),
		isExpired: isExpired(state),
		hasBDOTokens: hasBdoCredentials(state),
		apiType: getApiType(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		logout: () => {dispatch(logoutAction());},
		changeApiType: (apiType) => {dispatch(changeApiTypeAction(apiType));}
	};
};

export const TopMenu = connect(
	mapStateToProps,
	mapDispatchToProps
)(TopMenuComponent);


