import React from "react";
import {Form} from "react-final-form";
import {Button} from "../../../../components/button/button";
import {useStyles} from "./additionalSettings.styles";
import {FormFieldWrapper} from "../../../../components/formFieldWrapper/formFieldWrapper.component";
import {useHistory, useLocation} from "react-router-dom";
import Cookies from "js-cookie";
import {toast} from "react-toastify";
import {getConfigurationWithClosingButton} from "../../../../helpers/toast";
import {ISettingsAdditionalSettingsFormValues} from "../../../../interfaces/forms/settingsAdditionalSettings";
import {isRealTransportTimeFilterEnabled} from "../../../../helpers/cards";
import {Checkbox} from "../../../../components/checkbox/checkbox.component";

export function AdditionalSettingsSectionComponent(props) {
	const classes = useStyles({});

	/* eslint-disable */
	let history = useHistory();
	let location: any = useLocation();

	let { from } = location.state || { from: { pathname: "/" } };
	/* eslint-enable */

	const initialValues: ISettingsAdditionalSettingsFormValues = {
		isRealTransportTimeFilterEnabled: isRealTransportTimeFilterEnabled(),
	};

	const onSubmit = async (values: ISettingsAdditionalSettingsFormValues) => {
		Cookies.set("mbdo_isRealTransportTimeFilterEnabled", values.isRealTransportTimeFilterEnabled || "");

		toast.success("Zmiany zostały poprawnie zapisane.", getConfigurationWithClosingButton());
	};

	const handleValidation = (values: ISettingsAdditionalSettingsFormValues) => {
		const errors: any = {};

		// if (!values.clientId) {
		// 	errors.clientId = "To pole jest wymagane";
		// }

		return errors;
	};

	return (
		<>
			<Form
				validate={handleValidation}
				onSubmit={onSubmit}
				initialValues={initialValues}
				render={({ handleSubmit, form, submitting, pristine, values }) => {
					return (
						<form onSubmit={handleSubmit} className={classes.form}>
							<FormFieldWrapper>
								<Checkbox name="isRealTransportTimeFilterEnabled" alignLabel="center">Włącz filtrowanie kart po dacie transportu</Checkbox>
							</FormFieldWrapper>
							<FormFieldWrapper alignContent="right">
								<Button variant="contained" color="primary" type="submit" disabled={Boolean(Object.keys(handleValidation(values)).length)}>Zapisz zmiany</Button>
							</FormFieldWrapper>
						</form>
					);
				}}
			/>
		</>
	);
}
