import {generateConfirmation as generateConfirmationAPI} from "../../api/api";
import {
	TYPE_GENERATE_CONFIRMATION_CARDS,
	TYPE_GENERATE_CONFIRMATION_CARDS_FAILURE,
	TYPE_GENERATE_CONFIRMATION_CARDS_SUCCESS
} from "./types";
import { toast } from "react-toastify";
import {getGenerateConfirmationConfiguration} from "../../../../helpers/toast";

export function generateConfirmation(apiType, data) {
	return function(dispatch) {
		dispatch(generateConfirmationAction());

		return generateConfirmationAPI(apiType, data).then((response) => {
			dispatch(generateConfirmationSuccessAction(response));
		}).catch((error) => {
			dispatch(generateConfirmationFailureAction(error));
		});
	};
}

export function generateConfirmationAction() {
	return {
		type: TYPE_GENERATE_CONFIRMATION_CARDS,
		isLoading: true,
	};
}

export function generateConfirmationSuccessAction(response) {
	toast.success("Wygenerowano potwierdzenie przewozu. Upewnij się przed wyjazdem że posiadasz je na swoim telefonie.", getGenerateConfirmationConfiguration());

	return {
		type: TYPE_GENERATE_CONFIRMATION_CARDS_SUCCESS,
		isLoading: false,
	};
}

export function generateConfirmationFailureAction(error) {
	return {
		type: TYPE_GENERATE_CONFIRMATION_CARDS_FAILURE,
		isLoading: false,
		error,
	};
}
