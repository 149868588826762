import {finishTransportKPOK as finishTransportKPOKAPI} from "../../api/api";
import {TYPE_FINISH_TRANSPORT_KPOK, TYPE_FINISH_TRANSPORT_KPOK_FAILURE, TYPE_FINISH_TRANSPORT_KPOK_SUCCESS} from "./types";
import { toast } from "react-toastify";
import {getConfiguration} from "../../../../helpers/toast";

export function finishTransportKPOK(apiType, cardId, receiptEndingTime) {
	return function(dispatch) {
		dispatch(finishTransportKPOKAction());

		return finishTransportKPOKAPI(apiType, cardId, receiptEndingTime).then(function(response) {
			dispatch(finishTransportKPOKSuccessAction(response));
		}).catch((error) => {
			dispatch(finishTransportKPOKFailureAction(error));
		});
	};
}

export function finishTransportKPOKAction() {
	return {
		type: TYPE_FINISH_TRANSPORT_KPOK,
		isLoading: true,
	};
}

export function finishTransportKPOKSuccessAction(response) {
	toast.success("Zakończono transport", getConfiguration());

	return {
		type: TYPE_FINISH_TRANSPORT_KPOK_SUCCESS,
		isLoading: false,
		response,
	};
}

export function finishTransportKPOKFailureAction(error) {
	return {
		type: TYPE_FINISH_TRANSPORT_KPOK_FAILURE,
		isLoading: false,
		error,
	};
}
