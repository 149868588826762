import {
	TYPE_FETCH_APPROVED_CARDS,
	TYPE_FETCH_APPROVED_CARDS_FAILURE,
	TYPE_FETCH_APPROVED_CARDS_SUCCESS,
	TYPE_FETCH_CONFIRMATION_GENERATED_CARDS,
	TYPE_FETCH_CONFIRMATION_GENERATED_CARDS_FAILURE,
	TYPE_FETCH_CONFIRMATION_GENERATED_CARDS_SUCCESS,
	TYPE_FETCH_RECEIVE_CONFIRMATION_CARDS,
	TYPE_FETCH_RECEIVE_CONFIRMATION_CARDS_FAILURE,
	TYPE_FETCH_RECEIVE_CONFIRMATION_CARDS_SUCCESS,
	TYPE_FETCH_REJECTED_CARDS,
	TYPE_FETCH_REJECTED_CARDS_FAILURE,
	TYPE_FETCH_REJECTED_CARDS_SUCCESS,
	TYPE_FINISH_TRANSPORT,
	TYPE_FINISH_TRANSPORT_FAILURE,
	TYPE_FINISH_TRANSPORT_SUCCESS,
	TYPE_FINISH_TRANSPORT_KPOK,
	TYPE_FINISH_TRANSPORT_KPOK_FAILURE,
	TYPE_FINISH_TRANSPORT_KPOK_SUCCESS,
	TYPE_GENERATE_CONFIRMATION_CARDS,
	TYPE_GENERATE_CONFIRMATION_CARDS_FAILURE,
	TYPE_GENERATE_CONFIRMATION_CARDS_SUCCESS,
	TYPE_PRINT_CONFIRMATION,
	TYPE_PRINT_CONFIRMATION_FAILURE,
	TYPE_PRINT_CONFIRMATION_SUCCESS,
	TYPE_GET_REJECTED_CARD_DETAILS,
	TYPE_GET_REJECTED_CARD_DETAILS_SUCCESS,
	TYPE_GET_REJECTED_CARD_DETAILS_FAILURE, TYPE_SELECT_CARD_ON_SELECT_CARGO_SCREEN,
} from "../actions/types";
import {
	TYPE_CHANGE_API_TYPE,
	TYPE_CHANGE_PASSWORD,
	TYPE_CHANGE_PASSWORD_FAILURE,
	TYPE_CHANGE_PASSWORD_SUCCESS,
	TYPE_CLEAR_LOCATION_TO_CHANGE_TO,
	TYPE_CLEAR_REFRESH_CURRENT_PAGE,
	TYPE_GET_USER,
	TYPE_GET_USER_FAILURE,
	TYPE_GET_USER_SUCCESS,
	TYPE_LOGIN,
	TYPE_LOGIN_FAILURE,
	TYPE_LOGIN_SUCCESS,
	TYPE_LOGOUT,
	TYPE_REGISTER,
	TYPE_REGISTER_FAILURE,
	TYPE_REGISTER_SUCCESS,
	TYPE_UPDATE_BDO_CREDENTIALS,
	TYPE_UPDATE_BDO_CREDENTIALS_FAILURE,
	TYPE_UPDATE_BDO_CREDENTIALS_SUCCESS,
	TYPE_UPDATE_FORM_DATA,
} from "../../../../store/actions/types";

import {ICard} from "../../../../interfaces/getApprovedCardsResponse";
import {IPlaceOfBusiness} from "../../../../interfaces/placesOfBusiness";
import {IAccessToken} from "../../../../interfaces/accessToken";
import {IAccountData} from "../../../../interfaces/accountData";
import {getClearLocationToChangeToReducer} from "../../../../store/reducers/clearLocationToChangeTo";
import {getClearRefreshCurrentPageReducer} from "../../../../store/reducers/clearRefreshCurrentPage";
import {getUpdateFormDataReducer} from "../../../../store/reducers/updateFormData";
import {getDefaultActionReducer} from "../../../../store/reducers/defaultAction";
import {getLoginSuccessReducer} from "../../../../store/reducers/loginSuccess";
import {getLoginFailureReducer} from "../../../../store/reducers/loginFailure";
import {getDefaultFailureActionReducer} from "../../../../store/reducers/defaultFailureAction";
import {getUpdateBDOCredentialsSuccessReducer} from "../../../../store/reducers/updateBDOCredentialsSuccess";
import {getLogoutReducer} from "../../../../store/reducers/logout";
import {getGetUserReducer} from "../../../../store/reducers/getUser";
import {getGetUserSuccessReducer} from "../../../../store/reducers/getUserSuccess";
import {getGetUserFailureReducer} from "../../../../store/reducers/getUserFailure";
import {getRegisterSuccessReducer} from "../../../../store/reducers/registerSuccess";
import {getFetchApprovedCardsSuccessReducer} from "../../../../store/reducers/fetchApprovedCardsSuccess";
import {getFetchConfirmationGeneratedCardsSuccessReducer} from "../../../../store/reducers/fetchConfirmationGeneratedCardsSuccess";
import {getFetchRejectedCardsSuccessReducer} from "../../../../store/reducers/fetchRejectedCardsSuccess";
import {getPrintConfirmationSuccessReducer} from "../../../../store/reducers/printConfirmationSuccess";
import {getFinishTransportSuccessReducer} from "../../../../store/reducers/finishTransportSuccess";
import {getFinishTransportKPOKSuccessReducer} from "../../../../store/reducers/finishTransportKPOKSuccess";
import {getFetchReceiveConfirmationCardsSuccessReducer} from "../../../../store/reducers/fetchReceiveConfirmationCardsSuccess";
import {getGenerateConfirmationCardsSuccessReducer} from "../../../../store/reducers/generateConfirmationCardsSuccess";
import {getChangePasswordSuccessReducer} from "../../../../store/reducers/changePasswordSuccess";
import {getChangeApiTypeReducer} from "../../../../store/reducers/changeApiType";
import {ApiTypes} from "../../../../constants/apiTypes";
import {
	TYPE_GET_CARD_DETAILS,
	TYPE_GET_CARD_DETAILS_FAILURE,
	TYPE_GET_CARD_DETAILS_SUCCESS
} from "../actions/types";
import {
	getCardDetailsFailureReducer,
	getCardDetailsReducer,
	getCardDetailsSuccessReducer
} from "./getCardDetails";
import {
	getRejectedCardDetailsFailureReducer,
	getRejectedCardDetailsReducer,
	getRejectedCardDetailsSuccessReducer
} from "./getRejectedCardDetails";
import {getSelectCardOnSelectCargoScreenReducer} from "./selectCardOnSelectCargoScreen";

export interface IAppState {
	isDisconnected: boolean;
	isLoggedIn: boolean;
	isLoading: boolean;
	isBlocked: boolean;
	isExpired: boolean;
	hasBDOTokens: boolean | null;
	hasInvalidBDOTokens: boolean;
	accessToken: IAccessToken | null,
	accountData: IAccountData | null,
	isFetchingAccountData: boolean,
	isFetchingCardDetails: any, //object
	locationToChangeTo: string | null;
	refreshCurrentPageStatus: boolean | null;
	error: any | null; //object

	approvedCards: ICard[] | null,
	confirmationGeneratedCards: ICard[] | null;
	rejectedCards: ICard[] | null;
	receiveConfirmationCards: ICard[] | null;
	placesOfBusiness: IPlaceOfBusiness[] | null;
	cardsDetails: any, //object

	exchangedData: any; //object
	apiType: ApiTypes.KPO | ApiTypes.KPOK,
	selectedCardOnSelectCargoScreen: any;
}

const defaultState: IAppState = {
	isDisconnected: false,
	isLoggedIn: false,
	isLoading: false,
	isBlocked: false,
	isExpired: false,
	hasBDOTokens: null,
	hasInvalidBDOTokens: false,
	accessToken: null,
	accountData: null,
	isFetchingAccountData: false,
	isFetchingCardDetails: {},
	locationToChangeTo: null, //pomaga obejsc problem z polaczeniem Redux i ReactRouter (ma tylko triggerowac zmiane urla, nie wskazuje na poprawny aktualny adres)
	refreshCurrentPageStatus: null,
	error: null,

	approvedCards: null,
	confirmationGeneratedCards: null,
	rejectedCards: null,
	receiveConfirmationCards: null,
	placesOfBusiness: null,
	cardsDetails: {},

	exchangedData: {},
	apiType: ApiTypes.KPO,
	selectedCardOnSelectCargoScreen: null,
};

export function appReducers(state = defaultState, action) {
	switch (action.type) {
		case TYPE_CLEAR_LOCATION_TO_CHANGE_TO:
			return getClearLocationToChangeToReducer(state, action);
		case TYPE_CLEAR_REFRESH_CURRENT_PAGE:
			return getClearRefreshCurrentPageReducer(state, action);
		case TYPE_UPDATE_FORM_DATA:
			return getUpdateFormDataReducer(state, action);
		case TYPE_UPDATE_BDO_CREDENTIALS:
			return getDefaultActionReducer(state, action);
		case TYPE_UPDATE_BDO_CREDENTIALS_SUCCESS:
			return getUpdateBDOCredentialsSuccessReducer(state, action);
		case TYPE_UPDATE_BDO_CREDENTIALS_FAILURE:
			return getDefaultFailureActionReducer(state, action);
		case TYPE_LOGIN:
			return getDefaultActionReducer(state, action);
		case TYPE_LOGIN_SUCCESS:
			return getLoginSuccessReducer(state, action);
		case TYPE_LOGIN_FAILURE:
			return getLoginFailureReducer(state, action);
		case TYPE_GET_USER:
			return getGetUserReducer(state, action);
		case TYPE_GET_USER_SUCCESS:
			return getGetUserSuccessReducer(state, action);
		case TYPE_GET_USER_FAILURE:
			return getGetUserFailureReducer(state, action);
		case TYPE_REGISTER:
			return getDefaultActionReducer(state, action);
		case TYPE_REGISTER_SUCCESS:
			return getRegisterSuccessReducer(state, action);
		case TYPE_REGISTER_FAILURE:
			return getDefaultFailureActionReducer(state, action);
		case TYPE_LOGOUT:
			return getLogoutReducer(state, action);
		case TYPE_CHANGE_PASSWORD:
			return getDefaultActionReducer(state, action);
		case TYPE_CHANGE_PASSWORD_SUCCESS:
			return getChangePasswordSuccessReducer(state, action);
		case TYPE_CHANGE_PASSWORD_FAILURE:
			return getDefaultFailureActionReducer(state, action);

		case TYPE_FETCH_APPROVED_CARDS:
			return getDefaultActionReducer(state, action);
		case TYPE_FETCH_APPROVED_CARDS_SUCCESS:
			return getFetchApprovedCardsSuccessReducer(state, action);
		case TYPE_FETCH_APPROVED_CARDS_FAILURE:
			return getDefaultFailureActionReducer(state, action);
		case TYPE_FETCH_CONFIRMATION_GENERATED_CARDS:
			return getDefaultActionReducer(state, action);
		case TYPE_FETCH_CONFIRMATION_GENERATED_CARDS_SUCCESS:
			return getFetchConfirmationGeneratedCardsSuccessReducer(state, action);
		case TYPE_FETCH_CONFIRMATION_GENERATED_CARDS_FAILURE:
			return getDefaultFailureActionReducer(state, action);
		case TYPE_FETCH_REJECTED_CARDS:
			return getDefaultActionReducer(state, action);
		case TYPE_FETCH_REJECTED_CARDS_SUCCESS:
			return getFetchRejectedCardsSuccessReducer(state, action);
		case TYPE_FETCH_REJECTED_CARDS_FAILURE:
			return getDefaultFailureActionReducer(state, action);
		case TYPE_PRINT_CONFIRMATION:
			return getDefaultActionReducer(state, action);
		case TYPE_PRINT_CONFIRMATION_SUCCESS:
			return getPrintConfirmationSuccessReducer(state, action);
		case TYPE_PRINT_CONFIRMATION_FAILURE:
			return getDefaultFailureActionReducer(state, action);
		case TYPE_FINISH_TRANSPORT:
			return getDefaultActionReducer(state, action);
		case TYPE_FINISH_TRANSPORT_SUCCESS:
			return getFinishTransportSuccessReducer(state, action);
		case TYPE_FINISH_TRANSPORT_FAILURE:
			return getDefaultFailureActionReducer(state, action);
		case TYPE_FINISH_TRANSPORT_KPOK:
			return getDefaultActionReducer(state, action);
		case TYPE_FINISH_TRANSPORT_KPOK_SUCCESS:
			return getFinishTransportKPOKSuccessReducer(state, action);
		case TYPE_FINISH_TRANSPORT_KPOK_FAILURE:
			return getDefaultFailureActionReducer(state, action);
		case TYPE_FETCH_RECEIVE_CONFIRMATION_CARDS:
			return getDefaultActionReducer(state, action);
		case TYPE_FETCH_RECEIVE_CONFIRMATION_CARDS_SUCCESS:
			return getFetchReceiveConfirmationCardsSuccessReducer(state, action);
		case TYPE_FETCH_RECEIVE_CONFIRMATION_CARDS_FAILURE:
			return getDefaultFailureActionReducer(state, action);
		case TYPE_GENERATE_CONFIRMATION_CARDS:
			return getDefaultActionReducer(state, action);
		case TYPE_GENERATE_CONFIRMATION_CARDS_SUCCESS:
			return getGenerateConfirmationCardsSuccessReducer(state, action);
		case TYPE_GENERATE_CONFIRMATION_CARDS_FAILURE:
			return getDefaultFailureActionReducer(state, action);
		case TYPE_CHANGE_API_TYPE:
			return getChangeApiTypeReducer(state, action);
		case TYPE_GET_CARD_DETAILS:
			return getCardDetailsReducer(state, action);
		case TYPE_GET_CARD_DETAILS_SUCCESS:
			return getCardDetailsSuccessReducer(state, action);
		case TYPE_GET_CARD_DETAILS_FAILURE:
			return getCardDetailsFailureReducer(state, action);
		case TYPE_GET_REJECTED_CARD_DETAILS:
			return getRejectedCardDetailsReducer(state, action);
		case TYPE_GET_REJECTED_CARD_DETAILS_SUCCESS:
			return getRejectedCardDetailsSuccessReducer(state, action);
		case TYPE_GET_REJECTED_CARD_DETAILS_FAILURE:
			return getRejectedCardDetailsFailureReducer(state, action);
		case TYPE_SELECT_CARD_ON_SELECT_CARGO_SCREEN:
			return getSelectCardOnSelectCargoScreenReducer(state, action);
		default:
			return state;
	}
}
