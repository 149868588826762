import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	root: {

	},
	tableValue: {
		wordBreak: "break-all"
	},
	datetime: {
		width: "100%",
	},
	adviceContainer: {
		padding: "10px"
	}
}));

