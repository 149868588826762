import * as React from "react";
import {useState} from "react";
import {useStyles} from "./cardDetailsRejected.styles";
import classnames from "classnames";
import {getFormattedDateTime} from "../../helpers/datetime";
import {ICard} from "../../interfaces/getApprovedCardsResponse";
import {ICardDetails} from "../../interfaces/cardDetails";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {CardExtendedDetails} from "../card/extensions/cardExtendedDetails/cardExtendedDetails.component";
import {CardSubType} from "../card/extensions/cardSubType/cardSubType.component";

export function CardDetailsRejected(props) {
	const {card, cardDetails, color, isFetchingCardDetails}: {card: ICard, cardDetails: ICardDetails, color: string, isFetchingCardDetails: boolean} = props;
	const {hasExpandIcon = false, onShowDetailsClick} = props;

	const classes = useStyles({});
	const [expanded, toggleExpanded] = useState(false);

	let cardColor = color || "default";

	const getExpandButton = () => {
		if (!hasExpandIcon) {
			return null;
		} else {
			return (<div className={classes.expandButton}>{expanded ? <ExpandLessIcon onClick={handleExpandButtonClick} /> : <ExpandMoreIcon onClick={handleExpandButtonClick} />}</div>);
		}
	};

	function handleExpandButtonClick() {
		const newExpanded = !expanded;

		toggleExpanded(newExpanded);

		onShowDetailsClick(card, newExpanded);
	}

	return (
		<>
			<div className={classnames(classes.table)}>
				<div className={classnames(classes.row, classes.cardHeader, classes[cardColor])}>{card.cardNumber} {getExpandButton()}</div>
				{card.revisionNumber > 0 && <div className={classes.row}>
					<div className={classes.col1}>Korekta</div>
					<div className={classes.col2}>nr {card.revisionNumber}</div>
				</div>}
				<div className={classes.row}>
					<div className={classes.col1}>Przekazujacy</div>
					<div className={classes.col2}>{card.senderName}</div>
				</div>
				<div className={classes.row}>
					<div className={classes.col1}>Przyjmujący</div>
					<div className={classes.col2}>{card.receiverName}</div>
				</div>
				{card.cardRejectionTime && <div className={classes.row}>
					<div className={classes.col1}>Czas odrzucenia karty</div>
					<div className={classes.col2} title={card.cardRejectionTime}>{getFormattedDateTime(card.cardRejectionTime)}</div>
				</div>}
				<div className={classes.row}>
					<div className={classes.col1}>Nr rejestracyjny pojazdu</div>
					<div className={classes.col2}>{card.vehicleRegNumber}</div>
				</div>
				<div className={classes.row}>
					<div className={classes.col1}>Kod odpadu</div>
					<div className={classes.col2}>{card.wasteCode} - {card.wasteCodeDescription}</div>
				</div>
				<CardSubType card={card} />
				<CardExtendedDetails
					hasExpandIcon={hasExpandIcon}
					cardDetails={cardDetails}
					expanded={expanded}
					isFetchingCardDetails={isFetchingCardDetails}
				/>
			</div>
		</>
	);
}

