import * as React from "react";
import {MenuItem, Typography, AppBar, Toolbar, IconButton, Menu, Divider} from "@material-ui/core";
import {
	useHistory,
} from "react-router-dom";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";

import {useStyles} from "./topMenu.styles";
import {AccountCircle} from "@material-ui/icons";
import CachedIcon from "@material-ui/icons/Cached";
import {Locations} from "../../../../constants/locations";
import {ApiTypes} from "../../../../constants/apiTypes";
import {getLoginName} from "../../../../helpers/user";

export function TopMenuComponent(props) {
	const {isLoggedIn, logout, accountData, isExpired, apiType, changeApiType} = props;

	const classes = useStyles({});
	const history = useHistory();
	const loginName = getLoginName(accountData);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);


	const handleMenu = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogoutClick = () => {
		setAnchorEl(null);

		logout();
	};

	const handleReloadClick = () => {
		window.location.reload();
	};

	const handleLogoClick = () => {
		history.push(Locations.HOME);
	};

	const handleSettingsClick = () => {
		history.push(Locations.SETTINGS);
		setAnchorEl(null);
	};

	const handleApiTypeChange = (selectedApiType) => {
		setAnchorEl(null);
		changeApiType(selectedApiType);
		history.push(Locations.REDIRECT);
		history.push(Locations.HOME);
	};

	// const handleBackButton = () => {
	// 	history.goBack();
	// 	window.scrollTo(0, 0);
	// };

	return (
		<div className={classes.appBarWrapper}>
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar className={classes.toolbar}>
					<div className={classes.reloadButton}>
						<IconButton
							color="inherit"
							onClick={handleReloadClick}
						>
							<CachedIcon />
						</IconButton>
					</div>
					<Typography onClick={handleLogoClick} variant="h6" className={classes.title}>MobileBDO {isLoggedIn && <span className={classes.apiType}>{apiType}</span>}</Typography>
					{isLoggedIn && (
						<div className={classes.menuButton}>
							<IconButton
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleMenu}
								color="inherit"
							>
								<AccountCircle />
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								open={open}
								onClose={handleClose}
							>
								<div className={classes.userInfo}><PersonOutlineIcon className={classes.userInfoIcon} /><span>Jesteś zalogowany jako <b>{loginName}</b></span></div>
								<Divider />
								{isExpired ? null : (apiType === ApiTypes.KPO ? <MenuItem onClick={() => {handleApiTypeChange(ApiTypes.KPOK);}}>Włącz tryb komunalny ({ApiTypes.KPOK})</MenuItem> : <MenuItem onClick={() => {handleApiTypeChange(ApiTypes.KPO);}}>Włącz tryb zwykły ({ApiTypes.KPO})</MenuItem>)}
								<Divider />
								{!isExpired && <MenuItem onClick={handleSettingsClick}>Ustawienia</MenuItem>}
								<MenuItem onClick={handleLogoutClick}>Wyloguj</MenuItem>
							</Menu>
						</div>
					)}
				</Toolbar>
			</AppBar>
		</div>
	);
}



