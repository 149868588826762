import {connect} from "react-redux";
import {EndTransportComponent} from "./endTransport.component";
import {getFormData} from "../../../../store/selectors/app.selectors";
import {getError, getApiType} from "../../../../store/selectors/app.selectors";
import {finishTransportKPOK} from "../../store/actions";

const mapStateToProps = (state) => {
	return {
		exchangedData: getFormData(state),
		error: getError(state),
		apiType: getApiType(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		finishTransport: (apiType, cardId, receiptEndingTime) => {dispatch(finishTransportKPOK(apiType, cardId, receiptEndingTime));},
	};
};

export const EndTransportRoute = connect(
	mapStateToProps,
	mapDispatchToProps
)(EndTransportComponent);
