import {getRejectedCardDetails as getRejectedCardDetailsAPI} from "../../api/api";
import {TYPE_GET_REJECTED_CARD_DETAILS, TYPE_GET_REJECTED_CARD_DETAILS_FAILURE, TYPE_GET_REJECTED_CARD_DETAILS_SUCCESS} from "./types";

export function getRejectedCardDetails(apiType, cardId: string) {
	return function(dispatch) {
		dispatch(getRejectedCardDetailsAction(cardId));

		return getRejectedCardDetailsAPI(apiType, cardId).then((response) => {
			dispatch(getRejectedCardDetailsSuccessAction(response, cardId));
		}).catch((error) => {
			dispatch(getRejectedCardDetailsFailureAction(error, cardId));
		});
	};
}

export function getRejectedCardDetailsAction(cardId) {
	return {
		type: TYPE_GET_REJECTED_CARD_DETAILS,
		cardId: cardId,
	};
}
export function getRejectedCardDetailsSuccessAction(response, cardId) {
	return {
		type: TYPE_GET_REJECTED_CARD_DETAILS_SUCCESS,
		response: response,
		cardId: cardId,
	};
}

export function getRejectedCardDetailsFailureAction(error, cardId) {
	return {
		type: TYPE_GET_REJECTED_CARD_DETAILS_FAILURE,
		error,
		cardId,
	};
}
