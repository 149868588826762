import * as React from "react";
import {Button} from "../button/button";
import {useStyles} from "./cardReceiveConfirmation.styles";
import {globalStyles} from "../../css/global.styles";
import classnames from "classnames";
import {Card} from "../card/card.component";
import {CardWrapper} from "../cardWrapper/cardWrapper.component";

export function CardReceiveConfirmation(props) {
	const {card} = props;
	const classes = useStyles({});
	const globalClasses = globalStyles({});

	function handleEndCargo() {
		const {onEndCargoClick, card} = props;

		onEndCargoClick(card);
	}

	return (
		<CardWrapper type="highlight">
			<Card card={card} color="highlight" />

			<div className={classnames(classes.actionButtons , globalClasses.centerButtons)}>
				<Button onClick={handleEndCargo} className={classes.endCargoButton} variant="contained" color="primary">Zakończ transport</Button>
			</div>
		</CardWrapper>
	);
}
