import * as React from "react";
import {useStyles} from "./cardExtendedDetails.styles";
import {ICardDetails} from "../../../../interfaces/cardDetails";

export function CardExtendedDetails(props) {
	const {hasExpandIcon, cardDetails, expanded, isFetchingCardDetails}: {hasExpandIcon: boolean, cardDetails: ICardDetails, expanded: boolean, isFetchingCardDetails: boolean} = props;
	const classes = useStyles({});

	if (!hasExpandIcon || !expanded) {
		return null;
	}

	if (isFetchingCardDetails) {
		return (
			<div className={classes.row}>
				<div className={classes.col1}>Trwa ładowanie...</div>
			</div>
		);
	}

	// Nie przenoś tego wyzej, zablokuj jesli inne casy nie wystapily
	if (!cardDetails) {
		return null;
	}

	return (
		<div className={classes.row}>
			<div className={classes.col1}>Masa</div>
			<div className={classes.col2}>{cardDetails.wasteMass} Mg</div>
		</div>
	);
}

