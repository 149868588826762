import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
	appBar: {
		top: "51px !important"
	},
	coverSpace: {
		top: "48px",
		left: "auto",
		right: 0,
		position: "fixed",
		width: "100%",
		display: "flex",
		zIndex: 1100,
		boxSizing: "border-box",
		backgroundColor: "white",
		height: "10px",
	}
}));

