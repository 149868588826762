import React from "react";
import {get as lodashGet} from "lodash";
import {Form} from "react-final-form";
import {Button} from "../../../../components/button/button";
import {DatePicker} from "../../../../components/datePicker/datePicker";
import {TimePicker} from "../../../../components/timePicker/timePicker";
import {IEndTransportFormValues} from "../../interfaces/forms/endTransport";
import {useStyles} from "./endTransport.styles";
import {globalStyles} from "../../../../css/global.styles";
import {getCurrentDate, getCurrentTime} from "../../../../helpers/datetime";
import {ErrorMessage} from "../../../../components/errorMessage";
import {Header} from "../../../../components/header/header.component";
import {Redirect, useHistory} from "react-router-dom";
import {Locations} from "../../../../constants/locations";
import {Container} from "../../../../components/container/container.component";
import {FormFieldWrapper} from "../../../../components/formFieldWrapper/formFieldWrapper.component";

export function EndTransportComponent(props) {
	const {exchangedData, finishTransport, error, apiType} = props;
	const classes = useStyles({});
	const globalClasses = globalStyles({});

	let history = useHistory();

	const initialValues: IEndTransportFormValues = {
		realTransportTime: getCurrentTime(),
		realTransportDate: getCurrentDate(),
	};

	const handleGoBack = () => {
		history.push(Locations.ACTIVE_CARGOS);
	};

	const handleValidation = (values: IEndTransportFormValues) => {
		const errors: any = {};

		if (!values.realTransportTime) {
			errors.realTransportTime = "To pole jest wymagane";
		}

		if (!values.realTransportDate) {
			errors.realTransportDate = "To pole jest wymagane";
		}

		return errors;
	};

	const onSubmit = async (values: IEndTransportFormValues) => {
		const card = lodashGet(exchangedData, "card", {});

		finishTransport(apiType, card.kpoId || card.kpokId, `${values.realTransportDate}T${values.realTransportTime}:00.000Z`);
	};

	if (!Object.keys(lodashGet(exchangedData, "card", {})).length) {
		return (
			<Redirect
				to={{
					pathname: Locations.ACTIVE_CARGOS,
				}}
			/>
		);
	} else {
		return (
			<Container>
				<Header>
					Potwierdź transport odpadów
				</Header>
				<Form
					validate={handleValidation}
					onSubmit={onSubmit}
					initialValues={initialValues}
					render={({ handleSubmit, form, submitting, pristine, values}) => {
						return (
							<form onSubmit={handleSubmit}>
								<FormFieldWrapper>
									<DatePicker
										required={true}
										name="realTransportDate"
										label="Faktyczna data transportu"
										className={classes.datetime}
										serverErrors={error}
									/>
								</FormFieldWrapper>
								<FormFieldWrapper>
									<TimePicker
										required={true}
										name="realTransportTime"
										label="Faktyczna godzina transportu"
										className={classes.datetime}
										serverErrors={error}
									/>
								</FormFieldWrapper>
								<FormFieldWrapper alignContent="right">
									<Button onClick={handleGoBack} className={globalClasses.centerButton} variant="contained" color="secondary">
										Cofnij
									</Button>
									<Button className={globalClasses.centerButton} variant="contained" color="primary" type="submit">
										Potwierdź transport
									</Button>
								</FormFieldWrapper>
								<ErrorMessage errors={error} type="general" />
							</form>
						);
					}}
				/>
			</Container>
		);
	}
}



