import {getReceiveConfirmationCards as getReceiveConfirmationCardsAPI} from "../../api/api";
import {
	TYPE_FETCH_RECEIVE_CONFIRMATION_CARDS,
	TYPE_FETCH_RECEIVE_CONFIRMATION_CARDS_FAILURE,
	TYPE_FETCH_RECEIVE_CONFIRMATION_CARDS_SUCCESS
} from "./types";

export function fetchReceiveConfirmationCards(apiType) {
	return function(dispatch) {
		dispatch(fetchReceiveConfirmationCardsAction());

		return getReceiveConfirmationCardsAPI(apiType).then((response) => {
			dispatch(fetchReceiveConfirmationCardsSuccessAction(response));
		}).catch((error) => {
			dispatch(fetchReceiveConfirmationCardsFailureAction(error));
		});
	};
}

export function fetchReceiveConfirmationCardsAction() {
	return {
		type: TYPE_FETCH_RECEIVE_CONFIRMATION_CARDS,
		isLoading: true,
	};
}

export function fetchReceiveConfirmationCardsSuccessAction(response) {
	return {
		type: TYPE_FETCH_RECEIVE_CONFIRMATION_CARDS_SUCCESS,
		isLoading: false,
		response: response,
	};
}

export function fetchReceiveConfirmationCardsFailureAction(error) {
	return {
		type: TYPE_FETCH_RECEIVE_CONFIRMATION_CARDS_FAILURE,
		isLoading: false,
		error,
	};
}
