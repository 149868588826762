import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	root: {

	},
	datetime: {
		width: "100%",
	},
	error: {
		padding: "20px"
	},
}));
