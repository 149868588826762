import {connect} from "react-redux";
import {CargoConfirmationComponent} from "./cargoConfirmation.component";
import {getFormData} from "../../../../store/selectors/app.selectors";
import {getError, getApiType} from "../../../../store/selectors/app.selectors";
import {generateConfirmation} from "../../store/actions";
import {updateFormDataAction} from "../../../../store/actions";

const mapStateToProps = (state) => {
	return {
		exchangedData: getFormData(state),
		error: getError(state),
		apiType: getApiType(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		generateConfirmation: (apiType, data) => {dispatch(generateConfirmation(apiType, data));},
		updateFormData: (exchangedData) => {dispatch(updateFormDataAction(exchangedData));},
	};
};

export const CargoConfirmationRoute = connect(
	mapStateToProps,
	mapDispatchToProps
)(CargoConfirmationComponent);
