import {connect} from "react-redux";
import {AdditionalSettingsSectionComponent} from "./additionalSettings.component";

const mapStateToProps = (state) => {
	return {

	};
};

const mapDispatchToProps = (dispatch) => {
	return {

	};
};

export const AdditionalSettingsSection = connect(
	mapStateToProps,
	mapDispatchToProps
)(AdditionalSettingsSectionComponent);
