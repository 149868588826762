import * as React from "react";
import {useHistory} from "react-router-dom";
import {useStyles} from "./bottomMenu.styles";
import {AccountCircle} from "@material-ui/icons";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import ActiveTransportsIcon from "@material-ui/icons/Assignment";
import RejectedTransportsIcon from "@material-ui/icons/AssignmentLate";
import NewTransportIcon from "@material-ui/icons/LocalShipping";
import {Locations} from "../../../../constants/locations";

export function BottomMenuComponent(props) {
	const {hasBDOTokens, isExpired} = props;
	const classes = useStyles({});
	let history = useHistory();
	const initialSelectedOption = history.location.pathname === Locations.ACTIVE_CARGOS || history.location.pathname === Locations.HOME
		? 0
		: (history.location.pathname === Locations.REJECTED_CARGOS
			? 1
			: (history.location.pathname === Locations.SELECT_CARGO
				? 2
				: -1));
	const [selectedOption, setSelectedOption] = React.useState(initialSelectedOption);

	function handleChange(e, newValue) {
		setSelectedOption(newValue);
		switch(newValue) {
			case 0:
				history.push(Locations.ACTIVE_CARGOS);
				break;
			case 1:
				history.push(Locations.REJECTED_CARGOS);
				break;
			case 2:
				history.push(Locations.SELECT_CARGO);
				break;
		}
	}

	if (hasBDOTokens === false) {
		return (
			<BottomNavigation
				value={selectedOption}
				onChange={() => {history.push(Locations.SETTINGS);}}
				showLabels
				classes={{root: classes.rootError}}
			>
				<BottomNavigationAction classes={{root: classes.buttonRoot, selected: classes.buttonSelected, label: classes.buttonLabel}} label="Aby korzystać z aplikacji, uzupełnij tokeny BDO" icon={<AccountCircle />} />
			</BottomNavigation>
		);
	} else {
		return (
			<BottomNavigation
				value={selectedOption}
				onChange={handleChange}
				showLabels
				classes={{root: classes.root}}
			>
				<BottomNavigationAction disabled={isExpired} classes={{root: classes.buttonRoot, selected: classes.buttonSelected, label: classes.buttonLabel}} label="Aktywne" icon={<ActiveTransportsIcon />} />
				<BottomNavigationAction disabled={isExpired} classes={{root: classes.buttonRoot, selected: classes.buttonSelected, label: classes.buttonLabel}} label="Odrzucone" icon={<RejectedTransportsIcon />} />
				<BottomNavigationAction disabled={isExpired} classes={{root: classes.buttonRoot, selected: classes.buttonSelected, label: classes.buttonLabel}} label="Nowy" icon={<NewTransportIcon />} />
			</BottomNavigation>
		);
	}
}



