import {finishTransport as finishTransportAPI} from "../../api/api";
import {TYPE_FINISH_TRANSPORT, TYPE_FINISH_TRANSPORT_FAILURE, TYPE_FINISH_TRANSPORT_SUCCESS} from "./types";
import { toast } from "react-toastify";
import {getConfiguration} from "../../../../helpers/toast";

export function finishTransport(apiType, cardId) {
	return function(dispatch) {
		// dispatch(finishTransportSuccessAction({}));
		// return;
		dispatch(finishTransportAction());

		return finishTransportAPI(apiType, cardId).then(function(response) {
			dispatch(finishTransportSuccessAction(response));
		}).catch((error) => {
			dispatch(finishTransportFailureAction(error));
		});
	};
}

export function finishTransportAction() {
	return {
		type: TYPE_FINISH_TRANSPORT,
		isLoading: true,
	};
}

export function finishTransportSuccessAction(response) {
	toast.success("Zakończono transport", getConfiguration());

	return {
		type: TYPE_FINISH_TRANSPORT_SUCCESS,
		isLoading: false,
		response,
	};
}

export function finishTransportFailureAction(error) {
	return {
		type: TYPE_FINISH_TRANSPORT_FAILURE,
		isLoading: false,
		error,
	};
}
