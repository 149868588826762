import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
	return {
		root: {
			position: "fixed",
			bottom: 0,
			left:0,
			right: 0,
			height: "75px !important",
			zIndex: 10,
			backgroundColor: "#1a6c40 !important",
			color: "white !important",
		},
		rootError: {
			position: "fixed",
			bottom: 0,
			left:0,
			right: 0,
			height: "75px !important",
			zIndex: 10,
			backgroundColor: "#e30613 !important",
			color: "white !important",
		},
		buttonRoot: {
			color: "white !important",
			maxWidth: "200px !important",
		},
		buttonSelected: {
			backgroundColor: "rgba(255, 255, 255, 0.3)",
		},
		buttonLabel: {
			color: "white",
			backgroundColor: "transparent"
		}
	};
});

