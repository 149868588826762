import * as React from "react";
import {useStyles} from "./rejectedCargos.styles";
import classnames from "classnames";
import {CardDetailsRejected} from "../../../../components/cardDetailsRejected/cardDetailsRejected.component";
import {CardWrapper} from "../../../../components/cardWrapper/cardWrapper.component";
import {ICardDetails} from "../../../../interfaces/cardDetails";
import {Advice} from "../../../../components/advice";

export function RejectedCard(props) {
	const classes = useStyles({});
	const {card, onShowDetailsClick = () => {}} = props;
	const {cardDetails, isFetchingCardDetails}: {cardDetails: ICardDetails, isFetchingCardDetails: boolean} = props;

	return (
		<CardWrapper type="error">
			<CardDetailsRejected
				card={card}
				cardDetails={cardDetails}
				isFetchingCardDetails={isFetchingCardDetails}
				color="error"
				hasExpandIcon={true}
				onShowDetailsClick={onShowDetailsClick}
			/>

			<div className={classnames(classes.adviceContainer)}>
				<Advice type="info">
					Po skorygowaniu zmian przez przekazującego odpady będzie możliwość
					ponownego pobrania potwierdzenia dla tej karty w zakładce "Aktywne".
				</Advice>
			</div>
		</CardWrapper>
	);
}
