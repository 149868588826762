import {printConfirmation as printConfirmationAPI} from "../../api/api";
import {TYPE_PRINT_CONFIRMATION, TYPE_PRINT_CONFIRMATION_FAILURE, TYPE_PRINT_CONFIRMATION_SUCCESS} from "./types";

export function printConfirmation(apiType, card) {
	return function(dispatch) {
		dispatch(printConfirmationAction());

		return printConfirmationAPI(apiType, card.kpoId || card.kpokId).then(function(response) {
			dispatch(printConfirmationSuccessAction(response, card.cardNumber));
		}).catch((error) => {
			dispatch(printConfirmationFailureAction(error));
		});
	};
}

export function printConfirmationAction() {
	return {
		type: TYPE_PRINT_CONFIRMATION,
		isLoading: true,
	};
}

export function printConfirmationSuccessAction(response, cardNumber) {
	const file = new Blob([response.data], {type: "application/pdf"});
	const fileURL = URL.createObjectURL(file);

	var link: any = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
	link.href = fileURL;
	link.download = cardNumber + ".pdf";
	link.click();

	return {
		type: TYPE_PRINT_CONFIRMATION_SUCCESS,
		isLoading: false,
	};
}

export function printConfirmationFailureAction(error) {
	return {
		type: TYPE_PRINT_CONFIRMATION_FAILURE,
		isLoading: false,
		error,
	};
}
