import {connect} from "react-redux";
import {CargosToTerminateComponent} from "./cargosToTerminate.component";
import {finishTransport, fetchReceiveConfirmationCards} from "../../../../store/actions";
import {clearRefreshCurrentPageAction, updateFormDataAction} from "../../../../../../store/actions";
import {getReceiveConfirmationCards} from "../../../../store/selectors/app.selectors";
import {getApiType, getRefreshCurrentPageStatus} from "../../../../../../store/selectors/app.selectors";

const mapStateToProps = (state) => {
	return {
		receiveConfirmationCards: getReceiveConfirmationCards(state),
		refreshCurrentPageStatus: getRefreshCurrentPageStatus(state),
		apiType: getApiType(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		fetchReceiveConfirmationCards: (apiType) => {dispatch(fetchReceiveConfirmationCards(apiType));},
		finishTransport: (apiType, cardId) => {dispatch(finishTransport(apiType, cardId));},
		clearRefreshCurrentPage: () => {dispatch(clearRefreshCurrentPageAction());},
		updateFormData: (exchangedData) => {dispatch(updateFormDataAction(exchangedData));},
	};
};

export const CargosToTerminate = connect(
	mapStateToProps,
	mapDispatchToProps
)(CargosToTerminateComponent);
