import {connect} from "react-redux";
import {SelectCargoComponent} from "./selectCargo.component";
import {getApprovedCards, getSelectedCardOnSelectCargoScreen} from "../../store/selectors/app.selectors";
import {getApiType, getFormData} from "../../../../store/selectors/app.selectors";
import {fetchApprovedCards, selectCardOnSelectCargoScreenAction} from "../../store/actions";
import {updateFormDataAction} from "../../../../store/actions";
import {getSelectedCardDetails, isFetchingCardDetails} from "../../store/selectors/app.selectors";
import {getCardDetails} from "../../store/actions";

const mapStateToProps = (state) => {
	return {
		approvedCards: getApprovedCards(state),
		exchangedData: getFormData(state),
		apiType: getApiType(state),
		isFetchingCardDetails: isFetchingCardDetails(state),
		selectedCardDetails: getSelectedCardDetails(state),
		selectedCard: getSelectedCardOnSelectCargoScreen(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchApprovedCards: (apiType) => {dispatch(fetchApprovedCards(apiType));},
		updateFormData: (exchangedData) => {dispatch(updateFormDataAction(exchangedData));},
		getCardDetails: (apiType, cardId) => {dispatch(getCardDetails(apiType, cardId));},
		selectCardOnSelectCargoScreen: (selectedCard) => {dispatch(selectCardOnSelectCargoScreenAction(selectedCard));}
	};
};

export const SelectCargoRoute = connect(
	mapStateToProps,
	mapDispatchToProps
)(SelectCargoComponent);
