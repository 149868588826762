import {uniqBy} from "lodash";
import {ICard} from "../interfaces/getApprovedCardsResponse";
import {getDayDifference} from "./datetime";
import {CardSubTypes} from "../constants/cardSubTypes";
import {ICardDetails} from "../interfaces/cardDetails";
import Cookies from "js-cookie";

export function getUniqueRegistrationNumbers(cards: ICard[]): string[] {
	return uniqBy(cards, "vehicleRegNumber").map((card) => {
		return card.vehicleRegNumber;
	});
}

export function getFilteredCardsByRealTransportTime(cards: ICard[], transportTime: string, timeFilterProperty: string): ICard[] {
	if (!cards) {
		return [];
	}

	if (!transportTime) {
		return cards;
	}

	return cards.filter((card: ICard) => {
		return (card[timeFilterProperty] || "").indexOf(transportTime) > -1;
	});
}

export function getCardsByRegistrationNumber(cards: ICard[], vehicleRegNumber: string): ICard[] {
	if (!cards) {
		return [];
	}

	if (!vehicleRegNumber) {
		return cards;
	}

	return cards.filter((card: ICard) => {
		return card.vehicleRegNumber === vehicleRegNumber;
	});
}

export function getCardById(cards: ICard[], cardId: string) {
	return cards.find((card) => {
		return card.kpoId === cardId || card.kpokId === cardId;
	});
}

export function getInitialRegistrationNumber(vehicleRegNumberCookie, uniqueRegistrationNumbers) {
	if (vehicleRegNumberCookie) {
		const vehicleRegNumberCookieLC = vehicleRegNumberCookie.toLowerCase();

		for(let i = 0; i < uniqueRegistrationNumbers.length; i++) {
			const uniqueRegistrationNumber = uniqueRegistrationNumbers[i];
			const uniqueRegistrationNumberLC =  (uniqueRegistrationNumber || "").toLowerCase();

			if (uniqueRegistrationNumberLC.indexOf(vehicleRegNumberCookieLC) > -1) {
				return uniqueRegistrationNumber;
			}
		}
	}

	return "";
}

export function hasMissingInfoAboutCommune(cardDetails: ICardDetails) {
	return Boolean(cardDetails && cardDetails.cardSubtype === CardSubTypes.TRANSFER && (!cardDetails.communeId || !cardDetails.communeArea));
}

export function isOutOfTheTimeRange(card: ICard) {
	let isOutOfTheTimeRange = false;
	const diffDays = getDayDifference(new Date(), new Date(card.plannedTransportTime));

	if (diffDays > 1 || diffDays < -1) {
		isOutOfTheTimeRange = true;
	}

	return isOutOfTheTimeRange;
}

export function isCustomRegistrationNumberEnabled(): boolean {
	return Cookies.get("mbdo_vehicleHasRegNumberCustomPhrase") === "true";
}

export function getRegistrationNumberFromCookie() {
	return isCustomRegistrationNumberEnabled() ? Cookies.get("mbdo_vehicleRegNumberCustomPhrase") : Cookies.get("mbdo_vehicleRegNumber");
}

export function isRealTransportTimeFilterEnabled() {
	return Cookies.get("mbdo_isRealTransportTimeFilterEnabled") === "true";
}

export function getRealTransportTimeFilter() {
	return Cookies.get("mbdo_realTransportTimeFilter") || undefined;
}


