import {IGetCardsResponse} from "../../interfaces/getApprovedCardsResponse";
import {sortByDateAsc} from "../../helpers/datetime";

export function getFetchApprovedCardsSuccessReducer(state, action) {
	const approvedCardsResponse: IGetCardsResponse = action.response.data;

	return {
		...state,
		isLoading: action.isLoading,
		isDisconnected: false,
		error: null,
		approvedCards: sortByDateAsc(approvedCardsResponse.items, "plannedTransportTime"),
	};
}
