import React from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from "react-router-dom";
import {LoginRoute} from "../../../../routes/login";
import {ActiveCargosRoute} from "../../routes/activeCargos";
import {RejectedCargosRoute} from "../../routes/rejectedCargos";
import {SelectCargoRoute} from "../../routes/selectCargo";
import {EndTransportRoute} from "../../routes/endTransport";
import {CargoConfirmationRoute} from "../../routes/cargoConfirmation";
import {SelectPlaceOfBusinessRoute} from "../../../../routes/selectPlaceOfBusiness";
import {UpdateTokensRoute} from "../../../../routes/updateTokens";
import {AccountExpiredRoute} from "../../../../routes/accountExpired";
import {AccountBlockedRoute} from "../../../../routes/accountBlocked";
import {RegisterRoute} from "../../../../routes/register";
import {PrivateRoute} from "../../../../routes/privateRoute";
import {SettingsRoute} from "../../../../routes/settings";
import {TopMenu} from "../../widgets/topMenu";
import {BottomMenu} from "../../widgets/bottomMenu";
import {RouterHelper} from "../../../../containers/routerHelper";
import {Loader} from "../../../../components/loader";

import {createMuiTheme, responsiveFontSizes} from "@material-ui/core/styles";
import {ThemeProvider} from "@material-ui/core";
import ScrollToTop from "../../../../components/scrollToTop/scrollToTop.component";
import {useStyles} from "./app.styles";
import {Locations} from "../../../../constants/locations";
import {ToastContainer} from "react-toastify";
import {AccountTypes} from "../../../../constants/accountTypes";
import {RoleTypes} from "../../../../constants/roleTypes";

var lightGreen = {
	50: "#1a6c40",
	100: "#1a6c40",
	200: "#1a6c40",
	300: "#1a6c40",
	400: "#1a6c40",
	500: "#1a6c40",
	600: "#1a6c40",
	700: "#1a6c40",
	800: "#1a6c40",
	900: "#1a6c40",
	A100: "#1a6c40",
	A200: "#1a6c40",
	A400: "#1a6c40",
	A700: "#1a6c40",
	contrastText: "#ffffff",
};

var gray = {
	50: "#dbdbdb",
	100: "#dbdbdb",
	200: "#dbdbdb",
	300: "#dbdbdb",
	400: "#dbdbdb",
	500: "#dbdbdb",
	600: "#dbdbdb",
	700: "#dbdbdb",
	800: "#dbdbdb",
	900: "#dbdbdb",
	A100: "#dbdbdb",
	A200: "#dbdbdb",
	A400: "#dbdbdb",
	A700: "#dbdbdb",
	contrastText: "#000000",
};

let theme = createMuiTheme({
	palette: {
		primary: lightGreen,
		secondary: gray,
	},
	typography: {
		fontSize: 14,
		fontFamily: "Lato",
	},
});

theme = responsiveFontSizes(theme);

export function AppComponent(props) {
	const classes = useStyles({});
	const {isLoggedIn, isLoading} = props;

	return (
		<ThemeProvider theme={theme}>
			<Router>
				<ScrollToTop />
				<RouterHelper />
				<TopMenu />
				<ToastContainer />
				<div className={classes.container}>
					<Switch>
						<Route path={Locations.REGISTER}>
							<RegisterRoute accountType={AccountTypes.CARRIER} />
						</Route>
						<Route path={Locations.LOGIN}>
							<LoginRoute roleType={RoleTypes.CONVEYOR} />
						</Route>
						<Route path={Locations.SELECT_PLACE_OF_BUSINESS}>
							<SelectPlaceOfBusinessRoute roleType={RoleTypes.CONVEYOR} />
						</Route>
						<Route path={Locations.ACCOUNT_BLOCKED}>
							<AccountBlockedRoute />
						</Route>
						<PrivateRoute path={Locations.ACCOUNT_EXPIRED}>
							<AccountExpiredRoute />
						</PrivateRoute>
						<PrivateRoute path={Locations.END_TRANSPORT}>
							<EndTransportRoute />
						</PrivateRoute>
						<PrivateRoute path={Locations.UPDATE_TOKENS}>
							<UpdateTokensRoute />
						</PrivateRoute>
						<PrivateRoute path={Locations.SETTINGS}>
							<SettingsRoute />
						</PrivateRoute>
						<PrivateRoute path={Locations.ACTIVE_CARGOS}>
							<ActiveCargosRoute />
						</PrivateRoute>
						<PrivateRoute path={Locations.REJECTED_CARGOS}>
							<RejectedCargosRoute />
						</PrivateRoute>
						<PrivateRoute path={Locations.SELECT_CARGO}>
							<SelectCargoRoute />
						</PrivateRoute>
						<PrivateRoute path={Locations.CARGO_CONFIRMATION}>
							<CargoConfirmationRoute />
						</PrivateRoute>
						<PrivateRoute path={Locations.HOME}>
							<ActiveCargosRoute />
						</PrivateRoute>
					</Switch>
				</div>
				{isLoggedIn && <BottomMenu />}
			</Router>
			{<Loader show={isLoading} />}
		</ThemeProvider>
	);
}
