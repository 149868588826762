import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	root: {
	},
	row: {
		display: "flex",
		flexDirection: "row",
		flexGrow: 1
	},
	col1: {
		flexBasis: "40%",
		textAlign: "left",
		padding: "4px",
		fontWeight: "bold",
	},
	col2: {
		flexBasis: "60%",
		textAlign: "left",
		padding: "4px",
	}
}));
