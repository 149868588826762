import React, {useEffect} from "react";
import {Form} from "react-final-form";
import {get as lodashGet} from "lodash";
import {Textbox} from "../../components/textbox/textbox";
import {Button} from "../../components/button/button";
import {ErrorMessage} from "../../components/errorMessage";
import {Redirect, useHistory, useLocation} from "react-router-dom";
import {ILoginFormValues} from "../../interfaces/forms/login";
//import {useStyles} from "./selectPlaceOfBusiness.styles";
import {globalStyles} from "../../css/global.styles";
import {Header} from "../../components/header/header.component";
import {Container} from "../../components/container/container.component";
import {FormFieldWrapper} from "../../components/formFieldWrapper/formFieldWrapper.component";
import {Locations} from "../../constants/locations";
import {Select} from "../../components/select/select";
import {MenuItem} from "@material-ui/core";
import {IPlaceOfBusiness} from "../../interfaces/placesOfBusiness";

export function SelectPlaceOfBusinessComponent(props) {
	const {login, error, exchangedData, placesOfBusiness, accessToken, getUser, roleType} = props;
	//const classes = useStyles({});
	const globalClasses = globalStyles({});
	/* eslint-disable */
	let history = useHistory();
	/* eslint-enable */

	let location: any = useLocation();

	let { from } = location.state || { from: { pathname: "/" } };

	useEffect(() => {
		if (accessToken) {
			getUser(accessToken.decoded.sub, from.pathname);
		}
	}, [accessToken]);

	if (!Object.keys(lodashGet(exchangedData, "formValues", {})).length) {
		return (
			<Redirect
				to={{
					pathname: Locations.LOGIN,
				}}
			/>
		);
	} else {
		const initialValues: ILoginFormValues = {
			cardId: "",
			login: exchangedData.formValues.login,
			password: exchangedData.formValues.password,
			eupId: "",
		};

		const filteredPLacesOfBusiness = placesOfBusiness.filter((place: IPlaceOfBusiness) => {
			return place.isActive;
		});

		const onSubmit = async (values: ILoginFormValues) => {
			login(roleType, values);
		};

		const handleValidation = (values: ILoginFormValues) => {
			const errors: any = {};

			if (!values.login) {
				errors.login = "To pole jest wymagane";
			}

			if (!values.password) {
				errors.password = "To pole jest wymagane";
			}

			if (!values.eupId) {
				errors.eupId = "To pole jest wymagane";
			}

			return errors;
		};

		return (
			<Container>
				<Header>
					Wybierz miejsce prowadzenia działalności
				</Header>
				<Form
					validate={handleValidation}
					onSubmit={onSubmit}
					initialValues={initialValues}
					render={({ handleSubmit, form, submitting, pristine, values }) => (
						<form onSubmit={handleSubmit} noValidate={true}>
							<FormFieldWrapper hide={true}>
								<Textbox
									required={true}
									name="login"
									label="Nazwa użytkownika"
									className={globalClasses.inputWidth}
									serverErrors={error}
								/>
							</FormFieldWrapper>
							<FormFieldWrapper hide={true}>
								<Textbox
									required={true}
									name="password"
									type="password"
									label="Hasło"
									className={globalClasses.inputWidth}
								/>
							</FormFieldWrapper>
							<FormFieldWrapper>
								<Select
									required={true}
									name="eupId"
									label="Miejsce prowadzenia działalności"
									onChange={() => {form.change("cardId", "");}}
								>
									{filteredPLacesOfBusiness.map((place: IPlaceOfBusiness, index: number) => {
										return (<MenuItem key={place.eupId + index} value={place.eupId}>{place.name}</MenuItem>);
									})}
								</Select>
							</FormFieldWrapper>
							<FormFieldWrapper alignContent="right">
								<Button variant="contained" color="primary" type="submit" disabled={Boolean(Object.keys(handleValidation(values)).length)}>
									Wybierz
								</Button>
							</FormFieldWrapper>

							<ErrorMessage errors={error} type="general" />
						</form>
					)}
				/>
			</Container>
		);
	}
}



