import {createStore, applyMiddleware} from "redux";
import jwtDecode from "jwt-decode";
import reducers from "./reducers";
import thunk from "redux-thunk";
import {logger, crashReporter} from "../../../store/store.logger";
import Cookies from "js-cookie";
import {IAppState} from "./reducers/app.reducers";
import {ApiTypes} from "../../../constants/apiTypes";

const accessToken = Cookies.get("mbdo_at");
const apiType = Cookies.get("mbdo_api_type") || ApiTypes.KPO;
const hasAccessToken = !!accessToken;
const accessTokenData = hasAccessToken ? {
	original: accessToken,
	decoded: jwtDecode(accessToken),
} : null;

const isValidToken = accessTokenData && (accessTokenData.decoded.exp - Math.floor(new Date().getTime() / 1000) > 0);

const defaultState: IAppState = {
	isDisconnected: false,
	isLoggedIn: isValidToken,
	isLoading: false,
	isBlocked: false,
	isExpired: false,
	hasBDOTokens: null, // Zobacz getGetUserSuccessReducer
	hasInvalidBDOTokens: false,
	accessToken: accessTokenData,
	accountData: null, // nie zapisuj tutaj danych z cookiesa, zeby za kazdym razem robic requesta i moc porownac z danymi po logowaniu
	isFetchingAccountData: false,
	isFetchingCardDetails: {},
	locationToChangeTo: null,
	refreshCurrentPageStatus: null,
	error: null,

	approvedCards: null,
	confirmationGeneratedCards: null,
	rejectedCards: null,
	receiveConfirmationCards: null,
	placesOfBusiness: null,
	cardsDetails: {},

	exchangedData: {},
	apiType: apiType,
	selectedCardOnSelectCargoScreen: null,
};

export default createStore(
	reducers,
	{
		app: defaultState,
	},
	applyMiddleware(logger, crashReporter, thunk),
);
