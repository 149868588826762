import {connect} from "react-redux";
import {RejectedCargosComponent} from "./rejectedCargos.component";
import {fetchRejectedCards, printConfirmation, getRejectedCardDetails} from "../../store/actions";
import {getRejectedCards, getCardsDetails, isFetchingCardDetails} from "../../store/selectors/app.selectors";
import {getApiType} from "../../../../store/selectors/app.selectors";

const mapStateToProps = (state) => {
	return {
		rejectedCards: getRejectedCards(state),
		cardsDetails: getCardsDetails(state),
		apiType: getApiType(state),
		isFetchingCardDetails: isFetchingCardDetails(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		fetchRejectedCards: (apiType) => {dispatch(fetchRejectedCards(apiType));},
		printConfirmation: (apiType, cardId) => {dispatch(printConfirmation(apiType, cardId));},
		getRejectedCardDetails: (apiType, cardId) => {dispatch(getRejectedCardDetails(apiType, cardId));},
	};
};

export const RejectedCargosRoute = connect(
	mapStateToProps,
	mapDispatchToProps
)(RejectedCargosComponent);
