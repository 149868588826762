import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	root: {

	},

	container: {
		padding: "60px 0 80px 0",
		maxWidth: "700px",
		margin: "0 auto",
	}
}));

