import React from "react";
import {get as lodashGet} from "lodash";
import {Form} from "react-final-form";
import {Button} from "../../../../components/button/button";
import {DatePicker} from "../../../../components/datePicker/datePicker";
import {TimePicker} from "../../../../components/timePicker/timePicker";
import {ICargoConfirmationFormValues} from "../../interfaces/forms/cargoConfirmation";
import {useStyles} from "./cargoConfirmation.styles";
import {globalStyles} from "../../../../css/global.styles";
import {getCurrentDate, getCurrentTime} from "../../../../helpers/datetime";
import {ErrorMessage} from "../../../../components/errorMessage";
import {Header} from "../../../../components/header/header.component";
import {Redirect, useHistory} from "react-router-dom";
import {Locations} from "../../../../constants/locations";
import {Textbox} from "../../../../components/textbox/textbox";
import {Container} from "../../../../components/container/container.component";
import {FormFieldWrapper} from "../../../../components/formFieldWrapper/formFieldWrapper.component";
import {ApiTypes} from "../../../../constants/apiTypes";
import {containsError} from "../../../../helpers/errors";
import {Advice} from "../../../../components/advice";

export function CargoConfirmationComponent(props) {
	const {exchangedData, generateConfirmation, error, updateFormData, apiType} = props;
	const classes = useStyles({});
	const globalClasses = globalStyles({});

	let history = useHistory();

	const initialValues: ICargoConfirmationFormValues = {
		realTransportTime: getCurrentTime(),
		realTransportDate: getCurrentDate(),
		vehicleRegNumber: lodashGet(exchangedData, "formValues.vehicleRegNumber"),
	};

	const handleGoBack = () => {
		updateFormData({
			from: Locations.CARGO_CONFIRMATION,
			type: "goBack",
			formValues: lodashGet(exchangedData, "formValues"),
		});
		history.push(Locations.SELECT_CARGO);
	};

	const handleValidation = (values: ICargoConfirmationFormValues) => {
		const errors: any = {};

		if (!values.realTransportTime) {
			errors.realTransportTime = "To pole jest wymagane";
		}

		if (!values.realTransportDate) {
			errors.realTransportDate = "To pole jest wymagane";
		}

		if (!values.vehicleRegNumber) {
			errors.vehicleRegNumber = "To pole jest wymagane";
		}

		return errors;
	};

	const onSubmit = async (values: ICargoConfirmationFormValues) => {
		const cardId = lodashGet(exchangedData, "formValues.cardId");
		const vehicleRegNumber = values.vehicleRegNumber;
		const realTransportDate = `${values.realTransportDate}T${values.realTransportTime}:00.000Z`;

		if (apiType === ApiTypes.KPO) {
			generateConfirmation(apiType, {
				cardId: cardId,
				vehicleRegNumber: vehicleRegNumber,
				realTransportDate: realTransportDate,
			});
		} else if (apiType === ApiTypes.KPOK) {
			generateConfirmation(apiType, {
				cardId: cardId,
				vehicleRegNumber: vehicleRegNumber,
				realTransportDate: realTransportDate,
				cardSubtype: lodashGet(exchangedData, "selectedCard.cardSubtype"),
			});
		}
	};

	if (!Object.keys(lodashGet(exchangedData, "formValues", {})).length) {
		return (
			<Redirect
				to={{
					pathname: Locations.SELECT_CARGO,
				}}
			/>
		);
	} else {
		return (
			<Container>
				<Header>
					Generowanie potwierdzenia
				</Header>
				<Form
					validate={handleValidation}
					onSubmit={onSubmit}
					initialValues={initialValues}
					render={({ handleSubmit, form, submitting, pristine, values}) => {
						const communeDataNotFilled = containsError("communeId", error) || containsError("communeArea", error);

						return (
							<form onSubmit={handleSubmit}>
								<FormFieldWrapper>
									<Textbox
										required={true}
										name="vehicleRegNumber"
										label="Nr. rejestracyjny pojazdu"
										className={globalClasses.inputWidth}
										serverErrors={error}
									/>
								</FormFieldWrapper>
								<FormFieldWrapper>
									<DatePicker
										required={true}
										name="realTransportDate"
										label="Faktyczna data transportu"
										className={classes.datetime}
										serverErrors={error}
									/>
								</FormFieldWrapper>
								<FormFieldWrapper>
									<TimePicker
										required={true}
										name="realTransportTime"
										label="Faktyczna godzina transportu"
										className={classes.datetime}
										serverErrors={error}
									/>
								</FormFieldWrapper>
								<FormFieldWrapper alignContent="right">
									<Button onClick={handleGoBack} className={globalClasses.centerButton} variant="contained" color="secondary">
										Cofnij
									</Button>
									<Button className={globalClasses.centerButton} variant="contained" color="primary" type="submit">
										Wygeneruj
									</Button>
								</FormFieldWrapper>
								{communeDataNotFilled ? <Advice type="error">Potwierdzenie nie może zostać wygenerowane ponieważ karta nie zawiera informacji o nazwie gminy lub jej obszarze. Prosimy o kontakt z osobą wystawiającą kartę o uzupełnienie brakujących informacji.</Advice> : null}
								<ErrorMessage errors={error} type="general" />
							</form>
						);
					}}
				/>
			</Container>
		);
	}
}



