import {connect} from "react-redux";
import {ActiveCargosComponent} from "./activeCargos.component";
import {fetchConfirmationGeneratedCards, printConfirmation, finishTransport, fetchReceiveConfirmationCards} from "../../store/actions";
import {clearRefreshCurrentPageAction, updateFormDataAction} from "../../../../store/actions";
import {getConfirmationGeneratedCards, getReceiveConfirmationCards} from "../../store/selectors/app.selectors";
import {getApiType, getRefreshCurrentPageStatus} from "../../../../store/selectors/app.selectors";

const mapStateToProps = (state) => {
	return {
		confirmationGeneratedCards: getConfirmationGeneratedCards(state),
		receiveConfirmationCards: getReceiveConfirmationCards(state),
		refreshCurrentPageStatus: getRefreshCurrentPageStatus(state),
		apiType: getApiType(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		fetchConfirmationGeneratedCards: (apiType) => {dispatch(fetchConfirmationGeneratedCards(apiType));},
		fetchReceiveConfirmationCards: (apiType) => {dispatch(fetchReceiveConfirmationCards(apiType));},
		printConfirmation: (apiType, card) => {dispatch(printConfirmation(apiType, card));},
		finishTransport: (apiType, cardId) => {dispatch(finishTransport(apiType, cardId));},
		clearRefreshCurrentPage: () => {dispatch(clearRefreshCurrentPageAction());},
		updateFormData: (exchangedData) => {dispatch(updateFormDataAction(exchangedData));},
	};
};

export const ActiveCargosRoute = connect(
	mapStateToProps,
	mapDispatchToProps
)(ActiveCargosComponent);
